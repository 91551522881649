/* FOOTER */
.footer .content {
    padding: 50px 0;
}

.footer_logo {
    width: 100px;
    margin-right: 40px;
}

.footer ul {
    display: flex;
    list-style: none;
}

.footer li {
    margin: 0 20px;
    color: #000;
}

.footer button {
    color: #000;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    font-size: 1em;
    border: none;
    cursor: pointer;
}

@media (max-width: 1025px) {
    /* FOOTER */
    .footer .content {
        padding: 50px 0;
        flex-direction: column;
        justify-content: center !important;
        text-align: center;
        align-items: center;
    }

    .footer .content div:last-of-type {
        margin-top: 50px;
    }

    .footer_logo {
        width: 100px;
        margin-right: 40px;
    }
}

@media (max-width: 950px) {
    /* FOOTER */
    .footer_menu {
        display: flex;
        flex-direction: column;
    }

    .footer_menu img {
        margin: 0;
        margin-bottom: 25px;
    }
}

@media (max-width: 550px) {
    /* FOOTER */
    .footer_menu ul{
        display: flex;
        flex-direction: column;
    }

    .footer_menu li {
        padding: 10px;
    }
}