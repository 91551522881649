@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@font-face {
    font-family: Qanelas;
    src: url(/static/media/qanelas-black.f054d1c8.otf);
}

*, *:after, *:before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

* {
    font-family: Roboto, sans-serif;
}

html, body{
    width: 100%;
    z-index: 1;
    background-color: #fff;
}

img {
    max-width: 100%;
}

.container {
    display: flex;
    width: 100%;
}

.content {
    display: flex;
    width: 90%;
    margin: 0 auto;
    max-width: 1300px;
}

.fontzero {
    font-size: 0;
}

.ds-flex {
    display: flex;
}

.fd-column {
    flex-direction: column;
}

.fd-row {
    flex-direction: row;
}

.ai-center {
    align-items: center;
}

.jc-end {
    justify-content: flex-end;
}

.fl-left {
    float: left;
}

/* BOX */
.box_50 {
    width: 50%;
}

/* BTNS */
.btn {
    background: transparent;
    font-family: Qanelas;
    border: 2px solid #fff;
    border-radius: 25px;
    color: #fff;
    text-decoration: none;
    outline: none;
    padding: 10px 40px;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.btn:hover {
    background: -webkit-gradient(linear, left top, right top, from(rgba(255,102,0,1)), to(rgba(254,255,0,1)));
    background: linear-gradient(90deg, rgba(255,102,0,1) 0%, rgba(254,255,0,1) 100%);
    color: #000;
    border-color: #000;
}

.btn_main {
    border: none;
    padding: 15px 50px;
    font-size: 1.1em;
    background: rgb(255,102,0);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255,102,0,1)), to(rgba(254,255,0,1)));
    background: linear-gradient(90deg, rgba(255,102,0,1) 0%, rgba(254,255,0,1) 100%);
    color: #000;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.btn_black {
    color: #000;
    border-color: #000;
}

.btn_main:hover {
    margin-top: -5px;
    margin-bottom: 5px;
}

/* TITLES */
.big_title {
    font-family: Qanelas;
    font-size: 3em;
}

.small_title {
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 5px;
}

.sub_title {
    font-weight: 400;
}

/* FORMS */
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.form input,
.form select,
.form textarea {
    width: 100%;
    border-radius: 25px;
    outline: none;
    padding: 15px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
}

.form .legend {
    font-size: .8em;
    margin-left: 15px;
    margin-bottom: 5px;
    display: block;
    color: #fff;
}

.label_100 {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}


/* MAIN HEADER */
.header_main {
    background-color: #121E3D;
}

.header_main .content {
    padding: 40px 0;
    align-items: center;
    justify-content: space-between;
}

.header_main .logo {
    width: 125px;
    margin-right: 35px;
}

.header_main .ds-flex {
    align-items: center;
}

.menu_main {
    position: relative;
}

.menu_main button {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all .4s;
    transition: all .4s;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1em;
    outline: none;
}

.menu_main button:hover {
    color: #717171;
}

.header_main .language {
    background-color: transparent;
    border: none;
    color: #fff;
    outline: none;
    margin-left: 25px;
    cursor: pointer;
}

/* MOBILE */
.menu_mobile img {
    width: 30px;
    cursor: pointer;
    display: none;
}

.menu_mobile_close {
    width: 100%;
    padding: 20px 20px 5px 20px;
    display: none;
}

.menu_mobile_close img {
    width: 25px;
    cursor: pointer;
}

@media (max-width: 1025px) {
    /* MOBILE */
    .menu_mobile img {
        display: block;
    }

    .menu_mobile_close {
        display: block;
    }

    .menu_main button {
        width: 100%;
        padding: 10px 20px;
        text-align: left;
    }
}

@media (max-width: 550px) {
    /* MAIN HEADER */
    .header_main .logo {
        width: 90px;
        margin-right: 35px;
    }

    .btn_entrar {
        font-size: .7em;
        padding: 5px 15px;
    }

    .header_main .language {
        margin-left: 10px;
    }
}
/* DESTAQUE */
.destaque_main {
    color: #fff;
    background-color: #121E3D;
    position: relative;
}

.destaque_main::after {
    content: '';
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-image: url(/static/media/ondas.b313e921.svg);
    background-repeat: repeat-x;
    background-size: 100%;
    background-position: bottom;
}

.destaque_main .content {
    padding: 60px 0 150px 0;
}

.destaque_main .img_destaque {
    width: 450px;
}

.destaque_main .box_50 {
    display: flex;
    align-items: center;
}

.destaque_main .big_title {
    margin-bottom: 55px;
}

.destaque_main .sub_title {
    margin-bottom: 55px;
}

@media (max-width: 950px) {
    /* DESTAQUE */
    .destaque_main .content {
        flex-direction: column;
    }

    .destaque_main .box_50 {
        width: 100%;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    }

    .destaque_main .btn_main {
        margin-bottom: 60px;
    }
}
/* PRODUCT */
.product_emissor_online .content {
    padding: 100px 0 0 0;
}

.product_gateway .content {
    padding: 100px 0;
}

.product_gateway img {
    max-width: 500px;
    border-radius: 15px;
}

.product_emissor_online img {
    max-width: 500px;
    border-radius: 15px;
}

.product_emissor_online .box_divide {
    display: flex;
    align-items: center;
}

.product_emissor_online button,
.product_gateway button {
    font-size: 1em;
}

.product_gateway .box_divide {
    display: flex;
    align-items: center;
}

.product_emissor_online .btn,
.product_gateway .btn {
    margin-top: 25px;
}

.btn_black {

    margin-top: 25px;
}

@media (max-width: 950px) {
    /* PRODUCT */
    .product_emissor_online .content {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .product_gateway .content {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 50px 0;
    }

    .product_emissor_online .content div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        padding: 0 !important;
    }

    .product_gateway .content div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 !important;
    }

    .product_gateway .content .box_divide:last-of-type {
        margin-top: 50px;
    }

    .product_gateway img {
        width: 100%;
    }

    .product_emissor_online img {
        width: 100%;
    }
}
/* PLANOS */
.planos .content {
    padding: 20px 0 100px 0;
    flex-direction: column;
}

.planos .content .container{
    justify-content: space-between;
}

.planos .plan_box {
    width: 30%;
    text-align: center;
    border-radius: 25px;
    background-color: #F9F9F9;
    position: relative;
}

.plan_box header {
    padding: 20px;
    color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.plan_box header h1 {
    font-family: Qanelas;
    letter-spacing: 3px;
}

.plan_box header p {
    font-weight: 300;
    letter-spacing: 2px;
    margin-top: 5px;
}

.plan_box .plan_box_body {
    padding: 30px;
}

.plan_box_body .price {
    font-family: Qanelas;
    font-size: 2.3em;
    color: #727376;
}

.plan_box_body .mensal {
    font-size: .8em;
    color: #727376;
}

.plan_box_body ul {
    list-style: none;
    margin-top: 10px;
}

.plan_box_body ul li {
    border-bottom: dashed 1px #727376;
    margin: 10px 0;
    padding: 5px;
    color: #727376;
}

.plan_box_body ul li:last-of-type {
    border-bottom: none;
}

.box_btn_contratar {
    position: relative;
    width: 100%;
    height: 50px;
}

.plan_box button {
    cursor: pointer;
    outline: none;
    border: none;
    text-decoration: none;
    color: #fff;
    padding: 10px 25px;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    font-family: Qanelas;
    position: absolute;
    right: -44px;
    bottom: 0;
}

.plan_box button::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    bottom: -8px;
    right: 1px;
}

/* MODAL */
.container-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
    z-index: -1;
}
.content-modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}
.box-modal {
    -webkit-transition: all .5s;
    transition: all .5s;
    position: absolute;
    background-color: #121E3D;
    height: 100vh;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
}

.box-divide-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 2px solid #eee;
    padding-top: 30px;
    position: relative;
}

.steps {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    border-top: 1px solid #777;
    padding-top: 30px;
}

.steps button {
    width: 100%;;
}

.box-modal-content {
    flex: 1 1;
    overflow: auto;
    padding: 25px 35px;
}

.btn-close-modal {
    padding-top: 25px;
    padding-left: 35px;
}

.btn-close-modal img {
    width: 30px;
    cursor: pointer;
}

.chosePlan {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.chosePlan .plan {
    border: 2px solid #777;
    color: #777;
    border-radius: 5px;
    flex: 1 1;
    margin: 5px;
    padding: 20px 20px 13px 20px;
    text-align: center;
    cursor: pointer;
}

.chosePlan .plan.active {
    border-width: 5px;
    border-color: #fff;
    color: #fff;
}

.chosePlan .titlePlan {
    font-family: Qanelas;
    font-size: 1.2em;
    margin-bottom: 5px;
}

.chosePlan .pricePlan {
    font-size: .8em;
    margin: 10px 0;
}

.chosePlan input {
    width: 20px;
    height: 20px;
    display: none;
}

/* CARD */
.card_box {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 25px 0;
}

.cardNumber {
    font-weight: 600;
    font-size: 1.5em;
    margin: 10px 30px;
}

.cardExpiration {
    font-weight: 600;
    font-size: .9em;
    margin: 0 30px;
}

.cardExpirationLabel {
    font-weight: 400;
    font-size: .45em;
    margin: 0 30px;
}

.cardHolder {
    font-weight: 400;
    font-size: 1em;
    margin: 10px 30px 30px;
}

.box_flag {
    min-height: 70px;
}

.cardFlag {
    margin-top: 20px;
    margin-left: 30px;
    width: 60px;
}

.cardCvv {
    font-size: 1.5em;
    margin-right: -150px;
}

/* FLIP */
.flip-card {
    background-color: transparent;
    width: 100%;
    max-width: 320px;
    height: 200px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    display: flex;
}
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    /* transform: rotateY(180deg); */
}
  
.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 10px;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: #000;
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}


@media (max-width: 950px) {
    /* PLANOS */
    .planos .content .container{
        display: flex;
        flex-direction: column;
    }

    .planos .plan_box {
        width: 100%;
        margin-bottom: 50px;
    }

    .planos .plan_box:last-of-type {
        margin-bottom: 0;
    }
}
/* CONTATO */
.contato {
    background-color: #121E3D;
    color: #fff;
}

.contato .content{
    padding: 100px 0;
    align-items: center;
}

.contato .form {
    max-width: 400px;
}

.contato .form .btn_main {
    margin-top: 25px;
}

@media (max-width: 950px) {
    /* CONTACT */
    .contato .box_50 {
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center !important;
    }

    .contato .content{
        flex-direction: column;
    }

    .contato .form {
        text-align: left;
        margin-top: 50px;
    }
}
/* FOOTER */
.footer .content {
    padding: 50px 0;
}

.footer_logo {
    width: 100px;
    margin-right: 40px;
}

.footer ul {
    display: flex;
    list-style: none;
}

.footer li {
    margin: 0 20px;
    color: #000;
}

.footer button {
    color: #000;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    font-size: 1em;
    border: none;
    cursor: pointer;
}

@media (max-width: 1025px) {
    /* FOOTER */
    .footer .content {
        padding: 50px 0;
        flex-direction: column;
        justify-content: center !important;
        text-align: center;
        align-items: center;
    }

    .footer .content div:last-of-type {
        margin-top: 50px;
    }

    .footer_logo {
        width: 100px;
        margin-right: 40px;
    }
}

@media (max-width: 950px) {
    /* FOOTER */
    .footer_menu {
        display: flex;
        flex-direction: column;
    }

    .footer_menu img {
        margin: 0;
        margin-bottom: 25px;
    }
}

@media (max-width: 550px) {
    /* FOOTER */
    .footer_menu ul{
        display: flex;
        flex-direction: column;
    }

    .footer_menu li {
        padding: 10px;
    }
}
